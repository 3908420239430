<template>
  <div id="app">
    <router-view></router-view>
    <Loading
      :active.sync="isLoading"
      :is-full-page="true"
    ></Loading>
  </div>
</template>


<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";


export default {
  name: "App",
  components: {Loading},
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Inte";
  src: url("assets/fonts/Inter-Black.ttf"), url("assets/fonts/Inter-Bold.ttf"),
    url("assets/fonts/Inter-ExtraBold.ttf"),
    url("assets/fonts/Inter-ExtraLight.ttf") url("assets/fonts/Inter-Light.ttf"),
    url("assets/fonts/Inter-Medium.ttf"), url("assets/fonts/Inter-Regular.ttf"),
    url("assets/fonts/Inter-SemiBold.ttf"), url("assets/fonts/Inter-Thin.ttf");
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  text-decoration: none;
}

button {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  padding: 24px;
  height: 100vh;
  width: 100%;
}

.display {
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content {
  height: 70%;
  width: 100%;
}

.header {
  color: #009987;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 63px;
}

.text {
  color: #888888;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

@media screen and (min-width: 700px) {
  .app-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      rgba(0, 77, 68, 1) 0%,
      rgba(172, 247, 173, 1) 100%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}
</style>

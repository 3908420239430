import VueRouter from 'vue-router'

import store from '../store/'

const authRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            beforeResolve(to, from, next){
                store.dispatch('Logout')
    
                next({
                    name: 'Login'
                })
            },
        }
    }
]

const protectedRoutes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue'),
        meta: { authRequired: true}
    },
    {
        path: '/cadastro',
        name: 'Cadastro',
        component: () => import(/* webpackChunkName: "register" */ '../pages/Register.vue'),
        meta: { authRequired: false}
    },
    {
        path: '/recuperar-senha',
        name: 'Esqueci minha senha',
        component: () => import(/* webpackChunkName: "forgotpassword" */ '../pages/ForgotPassword.vue'),
        meta: { authRequired: false}
    },
    {
        path: '/detalhe/:id',
        name: 'Detalhe',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/Detail.vue'),
        meta: { authRequired: true}
    },
    {
        path: '/filmes',
        name: 'Filmes',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/Filmes.vue'),
        meta: { authRequired: true}
    },
    {
        path: '/series',
        name: 'Séries',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/Series.vue'),
        meta: { authRequired: true}
    },
    {
        path: '/busca',
        name: 'Buscas',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/Busca.vue'),
        meta: { authRequired: true}
    },
    {
        path: '/adicionar',
        name: 'Adicionar',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/Adicionar.vue'),
        meta: { authRequired: true}
    },
]

const routes = [...authRoutes, ...protectedRoutes]


const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((route) => route.meta.authRequired)

    if (authRequired && store.getters['loggedIn']) {
        store.dispatch('verify')   
        next()
    }

    if (authRequired && !store.getters['loggedIn']) {
        store.dispatch('Logout') 
        next()
    }

    if(to.fullPath === '/logout') {
        store.dispatch('Logout')
        next({name: 'Login'})
    }

    next()
})

export default router;

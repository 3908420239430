import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import Toast, { POSITION } from "vue-toastification";

// Import the CSS or use your own!

import router from './router/routes'
import store from './store/'

import "vue-toastification/dist/index.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

Vue.use(VueMask);
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(Toast, {
  // Setting the global default position
  position: POSITION.TOP_CENTER
});


Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')

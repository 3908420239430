import axios from 'axios'

const api = axios.create({
  //baseURL: 'http://localhost:8797/filmes/api/v1'
  baseURL: 'https://1rzg9j3gj7.execute-api.us-east-1.amazonaws.com/hom/filmes/api/v1'
  
})

api.defaults.headers.authorization = (localStorage["auth.access_token"]?"Bearer " +JSON.parse(localStorage["auth.access_token"]):'')

api.interceptors.response.use(
  response=> {
    return response.data
  }
)

export default api
import Vue from "vue";
import Vuex from "vuex";
import axios from "../plugins/axios";
import router from "../router/routes";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: getSavedState("auth.user"),
    access_token: getSavedState("auth.access_token"),
    isLoading: false,
  },
  getters: {
    loggedIn: (state) => !!state.user && !!state.access_token,
    getUser: (state) => state.user,
    getAccessToken: (state) => state.access_token,
    isLoading: (state) => state.isLoading
  },
  mutations: {
    SET_USER(state, newValue) {
      state.user = newValue;
      saveState("auth.user", newValue);
    },
    SET_ACCESS_TOKEN(state, newValue) {
      state.access_token = newValue;
      saveState("auth.access_token", newValue);
    },
    SET_IS_LOADING(state, newValue) {
      state.isLoading = newValue
    }
  },
  actions: {
    verify({ getters, dispatch }){
      if(!getters.loggedIn){
        dispatch('Logout')
      }else{
        dispatch("setDefaultAuthHeaders");
      }
    },
    LogIn({ commit, dispatch, getters }, { email, senha }) {
      if (getters.loggedIn) {
        router.push("/");
      } else {
        return axios.post("auth/login", { email, senha }).then((response) => {
          if (response.dados.usuario) {
            commit("SET_USER", response.dados.usuario);
            commit("SET_ACCESS_TOKEN", response.dados.access_token);
            dispatch("setDefaultAuthHeaders");
            router.push("/")
          } else {
            commit("SET_USER", null);
            commit("SET_ACCESS_TOKEN", null);
            dispatch("setDefaultAuthHeaders");
          }
          return response.data;
        });
      }
    },
  Logout({ commit, dispatch }) {
      commit('SET_USER', null)
      commit('SET_ACCESS_TOKEN', null)
      dispatch("setDefaultAuthHeaders");
      router.push("/login");
    },
    setDefaultAuthHeaders({ getters }) {
      if (getters.getAccessToken) {
        axios.defaults.headers.authorization = "Bearer " + getters.getAccessToken
      }
      else{
        delete axios.defaults.headers.Authorization;
      }
    },
    setIsLoading({ commit }, status) {
      commit('SET_IS_LOADING', status)
    }
  },
});

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

export default store;
